const knowledge_color_group = [
    { key: 1, color: "#9ACFFF" },
    { key: 2, color: "#C593EF" },
    { key: 3, color: "#FF8F34" },
    { key: 4, color: "#F08B8B" },
    { key: 5, color: "#26C0C0" },
    { key: 6, color: "#AA9100" },
    { key: 7, color: "#0EACF6" },
    { key: 8, color: "#AF5BE6" },
    { key: 9, color: "#E18C4F" },
    { key: 10, color: "#ED4242" },
    { key: 11, color: "#1C9797" },
    { key: 12, color: "#806D00" },
    { key: 13, color: "#9ACFFF" },
    { key: 14, color: "#C593EF" },
    { key: 15, color: "#FF8F34" },
    { key: 16, color: "#F08B8B" },
    { key: 17, color: "#26C0C0" },
    { key: 18, color: "#AA9100" },
    { key: 19, color: "#0EACF6" },
    { key: 20, color: "#AF5BE6" },
    { key: 21, color: "#E18C4F" },
    { key: 22, color: "#ED4242" },
    { key: 23, color: "#1C9797" },
    { key: 24, color: "#806D00" },
    { key: 25, color: "#9ACFFF" },
    { key: 26, color: "#C593EF" },
    { key: 27, color: "#FF8F34" },
    { key: 28, color: "#F08B8B" },
    { key: 29, color: "#26C0C0" },
    { key: 30, color: "#AA9100" },
    { key: 31, color: "#0EACF6" },
    { key: 32, color: "#AF5BE6" },
    { key: 33, color: "#E18C4F" },
    { key: 34, color: "#ED4242" },
    { key: 35, color: "#1C9797" },
    { key: 36, color: "#806D00" },
    { key: 37, color: "#9ACFFF" },
    { key: 38, color: "#C593EF" },
    { key: 39, color: "#FF8F34" },
    { key: 40, color: "#F08B8B" },
    { key: 41, color: "#26C0C0" },
    { key: 42, color: "#AA9100" },
    { key: 43, color: "#0EACF6" },
    { key: 44, color: "#AF5BE6" },
    { key: 45, color: "#E18C4F" },
    { key: 46, color: "#ED4242" },
    { key: 47, color: "#1C9797" },
    { key: 48, color: "#806D00" },
    { key: 49, color: "#9ACFFF" },
    { key: 50, color: "#C593EF" },
    { key: 51, color: "#FF8F34" },
    { key: 52, color: "#F08B8B" },
    { key: 53, color: "#26C0C0" },
    { key: 54, color: "#AA9100" },
    { key: 55, color: "#0EACF6" },
    { key: 56, color: "#AF5BE6" },
    { key: 57, color: "#E18C4F" },
    { key: 58, color: "#ED4242" },
    { key: 59, color: "#1C9797" },
    { key: 60, color: "#806D00" },
    { key: 61, color: "#9ACFFF" },
    { key: 62, color: "#C593EF" },
    { key: 63, color: "#FF8F34" },
    { key: 64, color: "#F08B8B" },
    { key: 65, color: "#26C0C0" },
    { key: 66, color: "#AA9100" },
    { key: 67, color: "#0EACF6" },
    { key: 68, color: "#AF5BE6" },
    { key: 69, color: "#E18C4F" },
    { key: 70, color: "#ED4242" },
    { key: 71, color: "#1C9797" },
    { key: 72, color: "#806D00" },
    { key: 73, color: "#9ACFFF" },
    { key: 74, color: "#C593EF" },
    { key: 75, color: "#FF8F34" },
    { key: 76, color: "#F08B8B" },
    { key: 77, color: "#26C0C0" },
    { key: 78, color: "#AA9100" },
    { key: 79, color: "#0EACF6" },
    { key: 80, color: "#AF5BE6" },
    { key: 81, color: "#E18C4F" },
    { key: 82, color: "#ED4242" },
    { key: 83, color: "#1C9797" },
    { key: 84, color: "#806D00" },
    { key: 85, color: "#9ACFFF" },
    { key: 86, color: "#C593EF" },
    { key: 87, color: "#FF8F34" },
    { key: 88, color: "#F08B8B" },
    { key: 89, color: "#26C0C0" },
    { key: 90, color: "#AA9100" },
    { key: 91, color: "#0EACF6" },
    { key: 92, color: "#AF5BE6" },
    { key: 93, color: "#E18C4F" },
    { key: 94, color: "#ED4242" },
    { key: 95, color: "#1C9797" },
    { key: 96, color: "#806D00" },
    { key: 97, color: "#9ACFFF" },
    { key: 98, color: "#C593EF" },
    { key: 99, color: "#FF8F34" },
    { key: 100, color: "#F08B8B" },
];
export default knowledge_color_group;